<template>
	<!-- 抽盒商品 -->
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div class="flex flex-ac flex-w">
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20 w-80">用户ID：</span>
						<el-input class="w-250" v-model="retrieveForm.userId" clearable></el-input>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20 w-80">用户名：</span>
						<el-input class="w-250" v-model="retrieveForm.userName" clearable></el-input>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20 w-80">手机号：</span>
						<el-input class="w-250" v-model="retrieveForm.phone" clearable></el-input>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20 w-80">兑换类型：</span>
						<el-select class="w-250" v-model="retrieveForm.keyType" clearable placeholder="请选择">
							<el-option v-for="item in exTypes" :key="item.value" :label="item.key" :value="item.value"
								v-if="userFlag == false?item.value!=2&&item.value!=3:item.value !== null && item.value !== undefined">
							</el-option>
						</el-select>
					</div>
					<div class="mr-30 mt-20 flex flex-ac">
						<span class="fs-16 flex1 mr-20 w-80">兑换码：</span>
						<el-input class="w-250" v-model="retrieveForm.cdkey" clearable></el-input>
					</div>
					<div class="mr-30 mt-20 flex flex-ac">
						<span class="fs-16 flex1 mr-20 w-80">创建时间：</span>
						<el-date-picker class="w-250" v-model="startEndDate" type="daterange" valueFormat="yyyy-MM-dd"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
							@change="handPickerChange">
						</el-date-picker>
					</div>
					<div class="mr-30 mt-20 flex flex-ac">
						<span class="fs-16 flex1 mr-20 w-80">是否兑换：</span>
						<el-select class="w-150" v-model="retrieveForm.isGet" clearable placeholder="请选择">
							<el-option label="未兑换" :value="0">
							</el-option>
							<el-option label="已兑换" :value="1">
							</el-option>
						</el-select>
					</div>
					<div class="mr-30 mt-20 flex flex-ac">
						<span class="fs-16 flex1 mr-20 w-80">是否过期：</span>
						<el-select class="w-150" v-model="retrieveForm.isGq" clearable placeholder="请选择">
							<el-option label="未过期" :value="0">
							</el-option>
							<el-option label="已过期" :value="1">
							</el-option>
						</el-select>
					</div>
					<div class="mt-20">
						<el-button type="primary" @click="submitSearch" class="mr-30">查询</el-button>
						<el-button type="primary" @click="addList">生成</el-button>
					</div>
				</div>
				<!-- <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div> -->
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%"
				:border="false" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:img="scope">
					<img :src="scope.row.userImg" alt="" class="tableImg">
				</template>
			</EleTable>
			<!-- 分页 -->
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
		</TableHeightFull>
		<!-- 道具 -->
		<el-dialog :title="dialogTitle" :append-to-body="true" :visible.sync="dialogFormVisible" @close="resetForm"
			:top="ruleForm.propType == 4?'8vh':'15vh'" width="30%">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px">
				<el-form-item label="可兑换类型" prop="keyType" v-if="'keyType' in ruleForm"
					:rules="{required: true, message: '请至少选择一个优惠劵类型', trigger: 'change'}">
					<el-select v-model="ruleForm.keyType" placeholder="请选择">
						<el-option v-for="item in exTypes" :key="item.value" :label="item.key" :value="item.value"
						v-if="userFlag == false?item.value!=2&&item.value!=3:item.value !== null && item.value !== undefined">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="有效天数" prop="validDay" v-if="'validDay' in ruleForm"
					:rules="{required: true, message: '有效天数不能为空', trigger: 'blur'}">
					<el-input type="number" v-model="ruleForm.validDay"></el-input>
				</el-form-item>
				<el-form-item label="固定用户" prop="userId" v-if="'userId' in ruleForm">
					<el-select v-model="ruleForm.userId" filterable remote placeholder="请输入手机号"
						:remote-method="remoteMethod" :loading="selLoading">
						<el-option v-for="item in userList" :key="item.id" :label="item.userName" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="affirmPopup" :loading="butLoading">{{dialogTitle == '新增'?'确 定':'保 存'}}
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'draws',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
		},
		computed: {
			...mapState('menu', {
				'exTypes': state => state.dictList ? state.dictList.ex_type : [], //优惠劵类型
			})
		},
		data() {
			return {
				userFlag: false,
				retrieveForm: {
					cdkey: '', //兑换码
					isGet: 0, //0 没有使用  1 已使用
					isGq: 0, //0 未过期  1 过期
					userId: '', //用户id
					userName: '', //用户名称,模糊查询
					phone: '', //用户手机号,模糊查询
					keyType: '', //可兑换类型
					startDate: '', //开始时间
					endDate: '', //结束时间
				},
				startEndDate: [], //时间
				selLoading: false,
				butLoading: false,
				tabLoading: false,
				columns: [{
					type: 'index',
					label: '序号',
					width: '60',
					align: 'center',
					fixed: 'left'
				}, {
					label: '兑换码',
					prop: 'cdkey',
					align: 'left',
					width: '180'
				}, {
					label: '用户ID',
					prop: 'userId',
					align: 'left',
					width: '80'
				}, {
					label: '用户名',
					prop: 'userName',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '手机号',
					prop: 'userPhone',
					align: 'left',
					width: '200'
				}, {
					columnType: 'fun',
					label: '可兑换类型',
					prop: 'keyType',
					align: 'left',
					width: '150',
					formatData: (e) => {
						let ele = this.exTypes.find(ele => ele.value == e)
						return ele ? ele.key : e
					}
				}, {
					label: '有效天数',
					prop: 'validDay',
					align: 'left',
					width: '120',
					'show-overflow-tooltip': true
				}, {
					columnType: 'fun',
					label: '是否兑换',
					prop: 'isGet',
					align: 'left',
					width: '120',
					'show-overflow-tooltip': true,
					formatData: (e) => {
						return e == 0 ? '未兑换' : '已兑换'
					}
				}, {
					label: '截至时间',
					prop: 'endDate',
					align: 'left',
					width: '180',
					'show-overflow-tooltip': true
				}, {
					label: '创建时间',
					prop: 'createDate',
					align: 'left',
					width: '180',
					'show-overflow-tooltip': true
				}, {
					columnType: 'button',
					label: '操作',
					align: 'right',
					fixed: 'right',
					width: '120',
					buttons: [{
						type: 'text',
						text: '编辑',
						fn: this.editList,
					}, {
						type: 'text',
						text: '删除',
						fn: this.deleteList,
					}],
				}, ],
				tableData: [],
				//弹框
				dialogTitle: '新增',
				dialogFormVisible: false,
				ruleForm: {
					id: '',
					keyType: '', //可兑换类型
					validDay: '', //有效天数
					userId: '', //用户id
				},
				userList: [], //用户列表
			}
		},
		mounted() {
			this.getTableData()
		},
		created() {
			//判断是否为admin
			let userInfo = JSON.parse(localStorage.getItem('userInfo'))
			if (userInfo.id == 3) {
				this.userFlag = true
			}
		},
		methods: {
			//查询
			submitSearch() {
				this.pageInfo.pageNum = 1
				this.getTableData()
			},
			//导出
			exportExcel() {},
			//时间变化
			handPickerChange(value) {
				if (value && value.length != 0) {
					this.retrieveForm.startDate = value[0]
					this.retrieveForm.endDate = value[1]
				} else {
					this.retrieveForm.startDate = ''
					this.retrieveForm.endDate = ''
				}
			},
			//获取表格数据
			getTableData() {
				this.tabLoading = true
				this.$http.get('/userCdkey/findUserKeyList', {
					params: {
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
						...this.retrieveForm
					}
				}).then(({
					data: result
				}) => {
					this.tabLoading = false
					this.tableData = result.data.list
					this.pageInfo.total = result.data.total
				})
			},
			//新增列表
			addList() {
				this.dialogTitle = '新增'
				this.ruleForm = {
					id: '',
					keyType: '', //可兑换类型
					validDay: '', //有效天数
					userId: '', //用户id
				}
				this.dialogFormVisible = true
			},
			//编辑列表
			editList(row) {
				this.dialogTitle = '编辑'
				this.ruleForm = {
					id: row.id,
					keyType: row.keyType, //可兑换类型
					validDay: row.validDay, //有效天数
					userId: row.userId, //用户id
				}
				this.dialogFormVisible = true
			},
			//保存道具
			affirmPopup() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.butLoading = true
						let dialogTitle = this.dialogTitle
						let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
						let apiURL = ''
						if (dialogTitle == '新增') {
							apiURL = '/userCdkey/createUsercdKey'
						} else if (dialogTitle == '编辑') {
							apiURL = '/userCdkey/editUserCdKey'
						}
						this.$http.post(apiURL, ruleForm).then(({
							data: result
						}) => {
							this.$message({
								message: dialogTitle + '成功',
								type: 'success'
							})
							this.butLoading = false
							this.dialogFormVisible = false
							this.getTableData()
						}).catch(err => {
							this.butLoading = false
						})
					}
				})
			},
			//删除
			deleteList(row) {
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/userCdkey/removeById', {
						id: row.id
					}).then(({
						data: result
					}) => {
						this.getTableData()
						this.$message({
							message: '删除成功',
							type: 'success'
						})
					})
				}).catch(_ => {})
			},
			//远程搜索方法
			remoteMethod(query) {
				if (query !== '') {
					this.selLoading = true
					this.$http.get('/appletUser/findAppletUserList', {
						params: {
							currentPage: 1,
							pageSize: 10,
							phone: query,
						}
					}).then(({
						data: result
					}) => {
						this.selLoading = false
						this.userList = result.data.list
					})
				} else {
					this.userList = []
				}
			},
			//重置清除校验
			resetForm() {
				Object.assign(this.$data.ruleForm, this.$options.data().ruleForm)
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})
			},
		},
	}
</script>
<style lang="less" scoped>
</style>
